import React from 'react';

const SafeIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
            <g clip-path="url(#clip0_1879_3988)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M28 18.0484V20.1452C28 26.0162 19.4452 29.371 16.8452 30.2097L15.9226 30.5452V26.9387L16.4258 26.771C20.5355 25.2613 24.6452 22.6613 24.6452 20.1452V18.0484H28ZM15 0.854858L18.5226 1.94518V5.63551L15 4.54518L14.7484 4.62905V12.9323H18.4387V16.371H14.7484V30.4613L13.7419 30.1258C13.3226 29.9581 12.5677 29.7065 11.6452 29.2871L11.2258 29.1194V1.94518L15 0.854858ZM10.2194 2.36454V6.05486L5.43871 7.48067V12.9323H10.2194V28.7839L9.12903 28.2807C5.85806 26.6033 2 23.8355 2 19.8936V18.0484H5.43871V19.8936C5.43871 20.7323 5.85806 21.571 6.69677 22.4097V16.371H2V4.7968L10.2194 2.36454ZM19.529 2.28067L27.9161 4.88067V16.4549H22.9677V21.4871C22.9677 21.4871 21.8774 22.9129 19.529 24.0871C19.529 23.4162 19.529 13.2678 19.529 7.06131V2.28067ZM23.0516 6.97744V12.9323H24.5613V7.48067L23.0516 6.97744Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_1879_3988">
                    <rect width="26" height="29.6903" fill="white" transform="translate(0.5 0.854858)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default SafeIcon;
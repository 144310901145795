import masterchefABI from '../abi/masterchef.json';
import masterchefRhABI from '../abi/masterchefRh.json';
import IERC20ABI from '../abi/erc20.json';
import StockTokenABI from '../abi/stockToken.json';
import StockPoolABI from '../abi/stockPool.json';
import ZapperABI from '../abi/zapper.json';
import LpABI from '../abi/lpToken.json';
import masterchefPLSXABI from '../abi/regularMasterchef.json';
import presaleABI from '../abi/presale.json';
export default {
  pcapToken: {
    address: '0xfE2895A52271adA8227f5439A20096217A9098b1',
    abi: IERC20ABI
  },
  masterChef: {
    address: '0xbE215D22E33c817cE8b322A8258521Bd9F06428B',
    abi: masterchefABI
  },
  masterChefRh: {
    address: '0x864e644bFc3EcF507de6c4ceECeFEdC84f821a07',
    abi: masterchefRhABI
  },
  stockToken: {
    address: '0x7329F6d1d72fCB7A3623504cadE84915b021ba3c',
    abi: StockTokenABI
  },
  stockPool: {
    address: '0xA65f348932c37De6DE11223A568B7c0348de4858',
    abi: StockPoolABI
  },
  zapper: {
    address: '0x7C60ddb62ba4bD23576f3a2afDE8826CbF526ADd',
    abi: ZapperABI
  },
  pair: {
    address: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
    abi: LpABI
  },
  masterchefPLSX: {
    address: '0xB2Ca4A66d3e57a5a9A12043B6bAD28249fE302d4',
    abi: masterchefPLSXABI
  },
  presale: {
    address: '0xe2e7aE7D2539965CB006708FFC034a2c5d27340F',
    abi: presaleABI
  }
  
}

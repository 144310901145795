const tokens = {
  pine: {
    symbol: 'PINE',
    address: '0xe846884430d527168b4eaac80af9268515d2f0cc',
    decimals: 18,
  },
  wpls: {
    symbol: 'WPLS',
    address: '0xa1077a294dde1b09bb078844df40758a5d0f9a27',
    decimals: 18,
  },
  atropa: {
    symbol: 'ATROPA',
    address: '0xCc78A0acDF847A2C1714D2A925bB4477df5d48a6',
    decimals: 18,
  },
    ehex: {
    symbol: 'eHEX',
    address: '0x57fde0a71132198BBeC939B98976993d8D89D225',
    decimals: 18,
  },
      pdai: {
    symbol: 'pdai',
    address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    decimals: 18,
  },
      pusdt: {
    symbol: 'pusdt',
    address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    decimals: 18,
  },
      pusdc: {
    symbol: 'pUSDC',
    address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    decimals: 18,
  },
  dai: {
    symbol: 'DAI',
    address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    decimals: 18,
  },
  wbtc: {
    symbol: 'WBTC',
    address:'0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    decimals: 18,
  },
  edai: {
    symbol: 'eDAI',
    address: '0xefD766cCb38EaF1dfd701853BFCe31359239F305',
    decimals: 18,
  },
  inc: {
    symbol: 'INC',
    address: '0x2fa878Ab3F87CC1C9737Fc071108F904c0B0C95d',
    decimals: 18,
  },
  nineinch: {
    symbol: '9INCH',
    address: '0x3ca80d83277e721171284667829c686527B8b3c5',
    decimals: 18,
  },
  wnineinch: {
    symbol: 'W9INCH',
    address: '0xeD22494279e0F3F1Ab491F823c6eC4055afeB21F',
    decimals: 18,
  },
  pp: {
    symbol: 'PoorPleb',
    address: '0x9565c2036963697786705120Fc59310F747bCfD0',
    decimals: 18,
  },
  pld: {
    symbol: 'PulseDoge',
    address: '0x9565c2036963697786705120Fc59310F747bCfD0',
    decimals: 18,
  },
  wheth: {
    symbol: 'WhereIsTheEth',
    address: '0xDe0220b69CE3e855a0124433A8E8D093f53A6bE4',
    decimals: 18,
  },
  bbc: {
    symbol: 'BBC',
    address: '0x8b4cfb020aF9AcAd95AD80020cE8f67FBB2C700E',
    decimals: 18,
  },
  plsx: {
    symbol: 'PLSX',
    address: '0x95B303987A60C71504D99Aa1b13B4DA07b0790ab',
    decimals: 18,
  },
  pcap: {
    symbol: 'PCAP',
    address: '0xfe2895a52271ada8227f5439a20096217a9098b1',
    decimals: 18,
  },
  stock: {
    symbol: 'STOCK',
    address: '0x7329f6d1d72fcb7a3623504cade84915b021ba3c',
    decimals: 18,
  },
  emp: {
    symbol: 'EMP',
    address: '0x9231937aC31506B6913ac5fb1DB5a1C1AE83783A',
    decimals: 18,
  },
  eth: {
    symbol: 'ETH',
    address: '0x02DcdD04e3F455D838cd1249292C58f3B79e3C3C',
    decimals: 18,
  },
  sdai: {
    symbol: 'sDAI',
    address: '0x30FCB23A906493371b1721C8feb8815804808D74',
    decimals: 18,
  },
  usdex: {
    symbol: 'USDEX',
    address: '0xaA2C47a35C1298795B5271490971Ec4874C8e53d',
    decimals: 18,
  },
  eusdc: {
    symbol: 'eUSDC',
    address: '0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07',
    decimals: 6,
  },
  pweth: {
    symbol: 'pWETH',
    address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    decimals: 18,
  },
    pwbtc: {
    symbol: 'pWBTC',
    address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    decimals: 18,
  },
    eusdt: {
    symbol: 'eUSDT',
    address: '0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f',
    decimals: 18,
  },
  hex: {
    symbol: 'HEX',
    address: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39',
    decimals: 18,
  },
}

export default tokens

import React from 'react';

const Metamask = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
        <path d="M30.1114 3.8999L19.1808 12.0182L21.2021 7.22853L30.1114 3.8999Z" fill="#E2761B" stroke="#E2761B" stroke-width="0.573771" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.87748 3.8999L15.7202 12.0951L13.7978 7.22853L4.87748 3.8999ZM26.1785 22.7182L23.2673 27.1783L29.4961 28.8921L31.2868 22.817L26.1785 22.7182ZM3.724 22.817L5.50366 28.8921L11.7325 27.1783L8.8213 22.7182L3.724 22.817Z" fill="#E4761B" stroke="#E4761B" stroke-width="0.573771" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.381 15.1821L9.64526 17.8077L15.8301 18.0823L15.6104 11.436L11.381 15.1821ZM23.6079 15.1821L19.3235 11.3591L19.1807 18.0823L25.3546 17.8077L23.6079 15.1821ZM11.7325 27.1783L15.4456 25.3657L12.2379 22.861L11.7325 27.1783ZM19.5433 25.3657L23.2674 27.1783L22.751 22.861L19.5433 25.3657Z" fill="#E4761B" stroke="#E4761B" stroke-width="0.573771" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M23.2674 27.1783L19.5433 25.3657L19.8399 27.7935L19.8069 28.8152L23.2674 27.1783ZM11.7325 27.1783L15.193 28.8152L15.171 27.7935L15.4457 25.3657L11.7325 27.1783Z" fill="#D7C1B3" stroke="#D7C1B3" stroke-width="0.573771" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.248 21.2571L12.15 20.3453L14.3362 19.3456L15.248 21.2571ZM19.741 21.2571L20.6528 19.3456L22.85 20.3453L19.741 21.2571Z" fill="#233447" stroke="#233447" stroke-width="0.573771" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.7326 27.1783L12.2599 22.7182L8.82141 22.817L11.7326 27.1783ZM22.7401 22.7182L23.2674 27.1783L26.1786 22.817L22.7401 22.7182ZM25.3547 17.8076L19.1808 18.0823L19.752 21.2571L20.6638 19.3456L22.861 20.3453L25.3547 17.8076ZM12.15 20.3453L14.3471 19.3456L15.248 21.2571L15.8302 18.0823L9.64533 17.8076L12.15 20.3453Z" fill="#CD6116" stroke="#CD6116" stroke-width="0.573771" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.64526 17.8076L12.2379 22.861L12.15 20.3453L9.64526 17.8076ZM22.8609 20.3453L22.751 22.861L25.3546 17.8076L22.8609 20.3453ZM15.8301 18.0823L15.2479 21.2571L15.9729 25.0032L16.1377 20.0706L15.8301 18.0823ZM19.1807 18.0823L18.8841 20.0597L19.0159 25.0032L19.752 21.2571L19.1807 18.0823Z" fill="#E4751F" stroke="#E4751F" stroke-width="0.573771" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19.752 21.257L19.016 25.0031L19.5433 25.3656L22.7511 22.8609L22.8609 20.3452L19.752 21.257ZM12.15 20.3452L12.2379 22.8609L15.4457 25.3656L15.973 25.0031L15.248 21.257L12.15 20.3452Z" fill="#F6851B" stroke="#F6851B" stroke-width="0.573771" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19.8069 28.8151L19.8399 27.7934L19.5652 27.5517H15.4237L15.171 27.7934L15.193 28.8151L11.7325 27.1782L12.941 28.1669L15.3907 29.8697H19.5982L22.059 28.1669L23.2674 27.1782L19.8069 28.8151Z" fill="#C0AD9E" stroke="#C0AD9E" stroke-width="0.573771" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19.5433 25.3657L19.016 25.0032H15.973L15.4457 25.3657L15.171 27.7935L15.4237 27.5518H19.5652L19.8399 27.7935L19.5433 25.3657Z" fill="#161616" stroke="#161616" stroke-width="0.573771" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M30.5729 12.5455L31.5067 8.06343L30.1115 3.8999L19.5434 11.7436L23.6081 15.1821L29.3535 16.8629L30.6279 15.3798L30.0786 14.9843L30.9574 14.1824L30.2763 13.6551L31.1552 12.985L30.5729 12.5455ZM3.49353 8.06343L4.4273 12.5455L3.83408 12.985L4.71293 13.6551L4.04281 14.1824L4.92165 14.9843L4.37238 15.3798L5.63571 16.8629L11.3812 15.1821L15.4458 11.7436L4.87771 3.8999L3.49353 8.06343Z" fill="#763D16" stroke="#763D16" stroke-width="0.573771" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M29.3533 16.8629L23.6079 15.1821L25.3546 17.8076L22.751 22.861L26.1785 22.817H31.2868L29.3533 16.8629ZM11.3809 15.1821L5.63549 16.8629L3.724 22.817H8.8213L12.2378 22.861L9.64522 17.8076L11.3809 15.1821ZM19.1807 18.0823L19.5432 11.7436L21.213 7.22852H13.7978L15.4456 11.7436L15.8301 18.0823L15.9619 20.0816L15.9729 25.0032H19.0159L19.0379 20.0816L19.1807 18.0823Z" fill="#F6851B" stroke="#F6851B" stroke-width="0.573771" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    );
};

export default Metamask;